import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Alert from '../../../reutilizable/Alert';
import { getDatosParticipante } from '../services/datosParticipanteService';
import { useEvaluationStore } from '../../../store/useEvaluationStore';
import styles from './DatosParticipante.module.css';

export const DatosParticipante = () => {
  const { selectedDataAcademico } = useEvaluationStore();

  const [datosParticipante, setDatosParticipante] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //console.log(selectedAcademico.codigo)
  useEffect(() => {
    getDatosParticipante(selectedDataAcademico?.codigo)
      .then(response => {
        setDatosParticipante(response.data);
        setError(null);
      })
      .catch(error => {
        console.error('Error al obtener los datos del participante', error.error)
        if (error.response) {
          setError(`Ocurrió un error al obtener los datos del participante: ${error.response.data.mensaje} - Status Code: ${error.response.status}`);
        }
      })
      .finally(() => { setLoading(false) })
  }, [selectedDataAcademico])

  // Desestructuramos las propiedades del dato del participante para una mejor lectura
  const {
    idSolicitud,
    codigo,
    nombre,
    apellidoP,
    apellidoM,
    ultimoGrado,
    nombreGradoAcademico,
    institucionOtorgante,
    email,
    telefono,
    ext,
    telefonoMovil,
    nacionalidad,
    entidadFederativa,
    nombramiento,
    horasFrenteGrupo,
    dependencia,
    municipio,
    fechaIngreso,
    antiguedad,
    puestoDirectivo,
    tipoPuestoDirectivo,
    tipoParticipacionNombre,
    tipoParticipacionDescripcion,
    evaluaciones,
    rfc,
    curp,
  } = datosParticipante;

  // funcion para mostrar Si = true, No = false para mejor formato en la interfaz
  const formatoBool = (value = '') => value ? 'Si' : 'No';

  if (loading) {
    return <Alert typeAlert='warning'><p>Cargando datos...</p></Alert>;
  }

  if (error) {
    return <Alert typeAlert='error'><p>{error}</p></Alert>;
  }

  // Verificamos si el objeto no tiene propiedades
  const isEmptyDatosParticipante = Object.values(datosParticipante).length === 0;
  if (isEmptyDatosParticipante) {
    return <Alert typeAlert='warning'><p>No hay datos para mostrar.</p></Alert>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerData}>
        {/* DATOS PERSONALES */}
        <div className={styles.datosPersonales}>
          <p className={styles.nombreDatos}>
            <FontAwesomeIcon icon={faAngleRight} color={'yellow'} /> {''}
            Datos personales
          </p>

          <div className={styles.containerTableData}>
            <div className={styles.inputContainer}>
              <label htmlFor='codigo'>Código</label>
              <input
                id='codigo'
                type='text'
                value={codigo || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='nombre'>Nombre</label>
              <input
                id='nombre'
                type='text'
                value={`${nombre || ''} ${apellidoP || ''} ${apellidoM || ''}` || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='rfc'>RFC</label>
              <input
                id='rfc'
                type='text'
                value={rfc || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='curp'>CURP</label>
              <input
                id='curp'
                type='text'
                value={curp || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='ultimoGrado'>Último Grado</label>
              <input
                id='ultimoGrado'
                type='text'
                value={ultimoGrado || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='nombreGradoAcademico'>Nombre de grado Acádemico</label>
              <input
                id='nombreGradoAcademico'
                type='text'
                value={nombreGradoAcademico || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='institucionOtorgante'>Institución Otorgante</label>
              <input
                id='institucionOtorgante'
                type='text'
                value={institucionOtorgante || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='correo'>Correo Electrónico</label>
              <input
                id='correo'
                type='email'
                value={email || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='telefono'>Teléfono</label>
              <input
                id='telefono'
                type='tel'
                value={telefono || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='telefonoMovil'>Teléfono Móvil</label>
              <input
                id='telefonoMovil'
                type='tel'
                value={telefonoMovil || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='nacionalidad'>Nacionalidad</label>
              <input
                id='nacionalidad'
                type='text'
                value={nacionalidad || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='entidadFederativa'>Entidad Federativa</label>
              <input
                id='entidadFederativa'
                type='text'
                value={entidadFederativa || 'Sin registro'}
                readOnly
              />
            </div>
          </div>
        </div>

        {/* DATOS DEL NOMBRAMIENTO O CONTRATO ACTUAL */}
        <div className={styles.datosNombramiento}>
          <p className={styles.nombreDatos}>
            <FontAwesomeIcon icon={faAngleRight} color={'yellow'} /> {''}
            Datos del nombramiento o contrato actual
          </p>

          <div className={styles.containerTableData}>
            <div className={styles.inputContainer}>
              <label htmlFor='nombramiento'>Nombramiento</label>
              <input
                id='nombramiento'
                type='text'
                value={nombramiento || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='horasFrenteGrupo'>Horas frente a grupo</label>
              <input
                id='horasFrenteGrupo'
                type='text'
                value={horasFrenteGrupo || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='dependencia'>Dependencia</label>
              <input
                id='dependencia'
                type='text'
                value={dependencia || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='municipio'>Municipio</label>
              <input
                id='municipio'
                type='text'
                value={municipio || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='fechaIngreso'>Fecha de Ingreso</label>
              <input
                id='fechaIngreso'
                type='text'
                value={fechaIngreso || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='antiguedad'>Antigüedad</label>
              <input
                id='antiguedad'
                type='text'
                value={antiguedad || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='esDirectivo'>¿Puesto directivo?</label>
              <input
                id='esDirectivo'
                type='text'
                value={formatoBool(puestoDirectivo) || 'Sin registro'}
                readOnly
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor='tipo'>Tipo</label>
              <input
                id='tipo'
                type='email'
                value={tipoParticipacionNombre || 'Sin registro'}
                readOnly
              />
            </div>
          </div>
        </div>

        {/* DATOS DE PARTICIPACION */}
        <div className={styles.datosNombramiento}>
          <p className={styles.nombreDatos}>
            <FontAwesomeIcon icon={faAngleRight} color={'yellow'} /> {''}
            Datos de participación
          </p>
          <div className={styles.containerTableData}>
            <div className={styles.inputContainer}>
              <label htmlFor='participacion'>Participación</label>
              <input
                id='participacion'
                type='text'
                value={tipoParticipacionDescripcion || 'Sin registro'}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className={styles.containerVisorDoc}>
            <div>Documentos recuperados de ALFRESCO</div>
          </div> */}
    </div>
  );
};
