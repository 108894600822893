import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TablaResultadosEvaluacion from './TablaResultadosEvaluacion';
import { datosEvaluacion, } from '../services/evaluacionService';
import styles from './styles/FormCriterios.module.css';
import arbolCriteriosJson from '../services/arbolCriterios.json';
import CriterioInput from './Criterio';

const FormCriterios = () => {
  const { control, handleSubmit, formState: { errors, isSubmitted } } = useForm();

  const [resultadosEvaluacion, setResultadosEvaluacion] = useState();
  const [arbolCriterios, setArbolCriterios] = useState([]);
  const [miembros, setMiembros] = useState([]);

  useEffect(() => {
    datosEvaluacion()
      .then(response => {
        setMiembros(response.data.miembros);
        setArbolCriterios(response.data.arbolCriterios);
      })
      .catch(error => console.log(error))
  }, [])

  useEffect(() => {
    setArbolCriterios(arbolCriteriosJson.arbolCriterios)
  }, [])

  //console.log(arbolCriterios)

  const onSubmit = (data) => {
    console.log(miembros);
    console.log(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.formEvaluacion}>
        {/* <h2 className={styles.tituloFormulario}>{criterios.nombre}</h2> */}
        {/* {arbolCriterios?.map((criterios, index) => {
          //console.log(criterios.criterio)
          const { criterio, nivel, subCriterios } = criterios;
          console.log(criterio)
          console.log(nivel)
          console.log(subCriterios)
          return (
            <div key={index}>
              <p>{criterios.criterio.nombre}</p>
            </div>
          )
        })} */}

        {arbolCriterios?.map((criterioObj, index) => {
          const { criterio, nivel, subCriterios } = criterioObj;
          return (
            <div key={index}>
              <p style={{ fontWeight: 900, fontSize: '1.5rem'}}>{criterio.nombre}</p> {/* TITULO DE LA SECCION DE CRITERIOS */}

              {subCriterios?.map(subCriterioObj => {
                const { criterio, nivel, subCriterios } = subCriterioObj
                return (
                  <>
                    <p style={{ fontWeight: 600 }}>{criterio.nombre}</p> {/* INCISOS DE LA SECCION DE CRITERIOS */}

                    {subCriterios?.map(subCriterioObj => {
                      const { criterio, nivel, subCriterios } = subCriterioObj
                      return (
                        /* DENTRO DE ESTA SECCION PUEDE O NO TENER UN INPUT YA QUE PUEDE SER UNA OPCION AL MISMO NIVEL DEL CRITERIO */
                        <>
                          <div>
                            {/* {criterio.} */}
                            <p style={{ fontWeight: 600 }}>{criterio.nombre}</p> {/* CRITERIO (PREGUNTA)*/}
                          </div>

                          {subCriterios.length > 0 && (
                            <>
                              {subCriterios.map(subCriterioObj => {
                                const { criterio, nivel, subCriterios } = subCriterioObj;
                                /* AQUI SE RENDERIZA LA LISTA DE OPCIONES DE CADA CRITERIO */
                                return (
                                  <>
                                    <div key={criterio.id} style={{ display: 'flex', gap: '5px', alignItems: 'flex-start', margin: '10px 15px'}}>
                                      <input type="number" style={{ width: '2rem', border: '1px solid'}}/>
                                      <label htmlFor="" style={{ fontWeight: 'normal'}}>{criterio.nombre}</label>
                                    </div>

                                    {subCriterios.length > 0 && (
                                      <>
                                        {subCriterios.map(subCriterioOpc => {
                                          const { criterio, nivel, subCriterio } = subCriterioOpc;
                                          return(
                                            <>
                                              <div key={criterio.id} style={{ display: 'flex', gap: '5px', alignItems: 'center', margin: '10px 15px'}}>
                                                <input type="number" style={{ width: '2rem', border: '1px solid'}}/>
                                                <label htmlFor="" style={{ fontWeight: 'normal'}}>{criterio.nombre}</label>
                                              </div>
                                            </>
                                          )
                                        })}
                                      </>
                                    )}
                                  </>
                                )
                              })}
                            </>
                          )}
                          
                        </>
                      )
                    })}
                  </>
                )
              })}
            </div>
          )
        })}
        <ul>


          {/* {arbolCriterios?.map((criterio, index) => {
          return (
            <CriterioInput 
              key={index}
              criterio={criterio}
              nivel={0}
            />  
          )
        })} */}

          {/* {arbolCriterios?.map((criterio, index) => (
          <CriterioInput
            key={index}
            criterio={criterio}
            control={control}
            namePrefix="criterios" // Prefijo inicial para nombres de campo
          />
        ))} */}
        </ul>

        {/* {criterios.arbolCriterios.map((nodo, index) => (
          <div key={index} className={styles.containerCriterios}>
            <h3 className={styles.criterio}>{nodo.seccionCriterios.nombreSeccionCriterios}</h3>
            {nodo.seccionCriterios.criterios.map((criterio, criterioIndex) => (
              <Criterio
                key={criterioIndex}
                criterio={criterio}
                namePrefix={`arbolCriterios.${index}.seccionCriterios.criterios.${criterioIndex}`}
                control={control}
              />
            ))}
          </div>
        ))} */}
      </div>

      {/* Sección del resto del formulario */}
      <div className={styles.restForm}>
        <div className={styles.containerInputsSelectRadio}>
          <div className={styles.inputSelect}>
            <select name="miembro" id="miembro">
              <option value="" disabled selected={true}>-- Seleccione su nombre de la lista --</option>
              {miembros.map(miembro => (
                <option key={miembro.codigo} value={miembro.codigo}>{miembro.nombre}</option>
              ))}
            </select>
          </div>
          <div className={styles.inputRadio}>
            <div>
              <input type='radio' id='ratifica' />
              <label htmlFor='ratifica'>Ratifica</label>
            </div>
            <div>
              <input type='radio' id='modifica' />
              <label htmlFor='modifica'>Modifica</label>
            </div>
          </div>
        </div>

        <div className={styles.textarea}>
          <label htmlFor='respuestaAcademico' style={{ marginBottom: 20 }}>Escriba la respuesta del académico</label>
          <textarea name="respuestaAcademico" id="respuestaAcademico"></textarea>
        </div>

        <div className={styles.submit}>
          <input type='submit' value='Evaluar' />
          {isSubmitted && (
            <TablaResultadosEvaluacion resultados={resultadosEvaluacion} />
          )}
        </div>
      </div>
    </form>
  );
}

export default FormCriterios
