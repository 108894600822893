// Custom hook: useFilteredMaterias.js
import { useState, useMemo } from 'react';

const useFilteredMaterias = (filteredMaterias) => {
  const [search, setSearch] = useState("");

  const filteredAndSearchedMaterias = useMemo(() => {
    return filteredMaterias.filter((materia) => {
      const codigoProfesor = materia.codigoProfesor || "";
      const nombreProfesor = materia.nombreProfesor || "";

      return (
        codigoProfesor.toLowerCase().includes(search.toLowerCase()) ||
        nombreProfesor.toLowerCase().includes(search.toLowerCase())
      );
    });
  }, [filteredMaterias, search]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return { filteredAndSearchedMaterias, handleSearchChange, search };
};

export default useFilteredMaterias;
