import React, { useState } from "react";
import { Menu, useMenu } from "../../../reutilizable/Menu";
import styles from "./styles/GenerarInconformidad.module.css";
import { DatosParticipante } from "../../visorDeDocumentos/components/DatosParticipante";
import { Inconformarse } from "./Inconformarse";
import Aside from "../../../reutilizable/Aside";

export const GenerarInconformidad = () => {
  const menu = useMenu(
    [
      {
        label: "Inconformarse",
        element: <Inconformarse />,
      },
      {
        label: "Datos participante",
        element: <DatosParticipante />,
      },
    ],
    {
      customClass: {
        menu: styles.menuContainer,
        menuOption: styles.menuOption,
        subMenuOption: styles.subMenuOption,
        selected: styles.selected,
      },
    }
  );

  const [infoAcademico, setInfoAcademico] = useState({
    id: 0,
    nombre: "",
    cargo: "",
    tipo: "",
    nivel: "",
  });

  return (
    <div className={styles.container}>
      <div className={styles.container_aside}>
        <Aside setInfoAcademico={setInfoAcademico} />
      </div>

      <div className={styles.container_content}>
        <div className={styles.container_menu}>
          {infoAcademico.nombre && (
            <div className={styles.nombre_academico}></div>
          )}
          <div className={styles.menu}>
            <Menu menu={menu} />
            <div className={styles.option_menu}>
              <h3 className={styles.font}>
                Programa de Estímulos al Desempeño Docente 2024 - 2025
              </h3>
              <p className={styles.nombreAcademico}>{infoAcademico.nombre.toUpperCase()}</p>
              {menu.element}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
