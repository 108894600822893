import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Alert from '../../../reutilizable/Alert';
import { getComentarios, postComentario } from '../services/notasService';
import styles from './styles/Notas.module.css';

/* 
 Ver la manera en como se apilan las entradas de notas (arriba o abajo)
*/
const Notas = () => {
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
    defaultValues: {
      comentario: '',
    }
  });

  const [comentarios, setComentarios] = useState([]);
  const [mensajeComentarioAgregado, setMensajeComentarioAgregado] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    getComentarios()
    .then(response => setComentarios(response))
    .catch(error => console.error('Error al obtener comentarios: ', error))
  }, [])

  const handleSubmitComentario = async (data) => {
    const body = { ...data, idSolicitud: 1 };
    await postComentario(body)
      .then(response => {
        if (response.status === 200) {
          setIsVisible(!isVisible);
          console.log(response)
          setMensajeComentarioAgregado(response.data.mensaje);
          reset();
        }
      })
      .catch(error => console.error('Error al agregar comentario: ', error))
  }

  useEffect(() => {
    const timer = setTimeout(() => { setIsVisible(false) }, 2000); 
    return () => clearTimeout(timer); // Limpia el temporizador al desmontar
  }, [isVisible])

  const VALIDATION = {
    comentario: {
      required: 'Debe escribir un comentario',
      minLength: { value: 5, message: 'El mensaje debe tener al menos 5 caracteres' },
    },
  };

  return (
    <div className={styles.containerNotas}>
      <p className={styles.tituloNotas}>
        <FontAwesomeIcon icon={faAngleRight} color={'yellow'} /> {''}
        Puede agregar tantas entradas como desee
      </p>

      <div className={styles.containerEntradas}>
        {comentarios?.map(comentario => (
          <div key={comentario.id} className={styles.containerEntradaComentario}>
            <p>
              <span>[{comentario.fechaFormato} - {comentario.usuario}]</span>
              {comentario.comentario}
            </p>
          </div>
        ))}
      </div>

      <form action="" onSubmit={handleSubmit(handleSubmitComentario)}>
        <div className={styles.containerAgregarEntrada}>
          <div className={styles.containerTextareaEntrada}>
            <p>Agregar entrada</p>
            <textarea 
              rows={6}
              className={errors.comentario ?? `${styles.containerTextareaEntradaError} : ''`}
              {...register('comentario', VALIDATION.comentario)}
            ></textarea>
          </div>

          {errors.comentario && (
            <Alert typeAlert={'error'}>
              <p>{errors.comentario.message}</p>
            </Alert>
          )}

          {isVisible && (
            <Alert typeAlert={'success'}>
              <p>{mensajeComentarioAgregado}</p>
            </Alert>
          )}

          <div className={styles.buttonsTextareaEntrada}>
            <button type='submit'>Agregar</button>
            <button type='button' className={styles.btnComentarioLeido}>Comentario leído</button>
            <button type='button' className={styles.btnCancelar}>Cancelar</button>
          </div>
        </div>
      </form>

    </div>
  )
}

export default Notas;
