import React from "react";
import './styles.css'

import { Navigation } from "./router/Navigation";

function App() {
  return <Navigation />;
}

export default App;
