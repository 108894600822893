import React from 'react';
import styles from './styles/TablaResultadosEvaluacion.module.css';

const TablaResultadosEvaluacion = ({ resultados }) => {
  return (
    <table className={styles.tablaResultados}>
        <thead>
            <tr className={styles.rowHeadTable}>
                <th colSpan={3}>
                    Resultados <br/>
                    <span>Nivel: V</span>
                </th>
            </tr>
            <tr className={styles.rowHeadTh}>
                <th className={styles.tdTextCenter}>Criterio</th>
                <th className={styles.tdTextCenter}>Subtotal</th>
                <th className={styles.tdTextCenter}>Total</th>
            </tr>
        </thead>

        <tbody className={styles.bodyTable}>
            <tr>
                <td>I. Calidad en el desempeño de la docencia</td>
                <td className={styles.tdCalificacion}></td>
                <td className={styles.tdCalificacion}>460</td>
            </tr>
            <tr>
                <td className={styles.tdNombreCriterio}>A. A)Docencia</td>
                <td className={styles.tdCalificacion}>220</td>
                <td className={styles.tdCalificacion}></td>
            </tr>
            <tr>
                <td className={styles.tdNombreCriterio}>B. B) Generación y/o aplicación del conocimiento </td>
                <td className={styles.tdCalificacion}>70</td>
                <td className={styles.tdCalificacion}></td>
            </tr>
            <tr>
                <td className={styles.tdNombreCriterio}>C. C) Tutorías</td>
                <td className={styles.tdCalificacion}>70</td>
                <td className={styles.tdCalificacion}></td>
            </tr>
            <tr>
                <td className={styles.tdNombreCriterio}>D. D) Gestión académica individual o colegiada</td>
                <td className={styles.tdCalificacion}>100</td>
                <td className={styles.tdCalificacion}></td>
            </tr>
            <tr>
                <td>II. Dedicación a la docencia</td>
                <td className={styles.tdCalificacion}></td>
                <td className={styles.tdCalificacion}>1000</td>
            </tr>
            <tr>
                <td>III. Permanencia en las actividades académicas de la institución</td>
                <td className={styles.tdCalificacion}></td>
                <td className={styles.tdCalificacion}>60</td>
            </tr>
            <tr className={styles.rowFooterTable}>
                <td className={styles.tdTotal}>Total:</td>
                <td className={styles.tdCalificacion}></td>
                <td className={styles.tdCalificacion}>620</td>
            </tr>
        </tbody>
    </table>
  )
}

export default TablaResultadosEvaluacion;
