import React from "react";
import { Footer, Header } from "../../layout/components";
import { InstruccionesComponent } from "../components/InstruccionesComponent";

export const InstruccionesPage = () => {

  return (
    <>
      <Header/>
      <InstruccionesComponent/>
      <Footer />
    </>
  );
};
