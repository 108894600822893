import { useEffect, useCallback } from 'react';
import { obtenerMaterias } from "../features/materiasService";

const useMaterias = (setMaterias, selectedCiclo, setFilteredMaterias) => {
  const fetchMaterias = useCallback(async () => {
    try {
      const response = await obtenerMaterias();

      if (typeof setMaterias === 'function') {
        setMaterias(response);
      } else {
        console.error("setMaterias is not a function. Make sure it is defined correctly in the component.");
      }

      if (typeof setFilteredMaterias === 'function') {
        setFilteredMaterias(response.filter((materia) => materia.cicloEscolar === selectedCiclo));
      } else {
        console.error("setFilteredMaterias is not a function. Make sure it is defined correctly in the component.");
      }

    } catch (error) {
      console.error("Error al obtener las materias:", error);
    }
  }, [selectedCiclo, setMaterias, setFilteredMaterias]);

  useEffect(() => {
    fetchMaterias();
  }, [fetchMaterias]);

  return { fetchMaterias };
};

export default useMaterias;
