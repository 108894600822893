import React, { useState } from 'react';
import { faAngleRight, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Search from '../../../reutilizable/Search';
import Select from '../../../reutilizable/Select';
import Table from '../../../reutilizable/Table';
import styles from './styles/ReporteResultadosEvaluacion.module.css';

const ReporteResultadosEvaluacion = () => {
  const [bodyTable, _] = useState([
    {
      id: 1,
      num: 1,
      nombre: 'Reynoso Alvarez Alejandro',
      tipo: 'Evaluacion',
      dependencia: 'admgral',
      nivel: 'V',
      calidad: 430,
      pts: 630
    },
    {
      id: 2,
      num: 2,
      nombre: 'Reynoso Alvarez Alejandro',
      tipo: 'Evaluacion',
      dependencia: 'admgral',
      nivel: 'V',
      calidad: 430,
      pts: 630
    },
    {
      id: 3,
      num: 3,
      nombre: 'Reynoso Alvarez Alejandro',
      tipo: 'Evaluacion',
      dependencia: 'admgral',
      nivel: 'V',
      calidad: 430,
      pts: 630
    },
    {
      id: 4,
      num: 4,
      nombre: 'Reynoso Alvarez Alejandro',
      tipo: 'Evaluacion',
      dependencia: 'admgral',
      nivel: 'V',
      calidad: 430,
      pts: 630
    },
  ]);

  const CABECERAS_TABLA = [
    {
      id: 1,
      labelCabecera: 'inp'
    },
    {
      id: 2,
      labelCabecera: '#'
    },
    {
      id: 3,
      labelCabecera: 'Apellido(S) Nombre(s)'
    },
    {
      id: 4,
      labelCabecera: 'Tipo'
    },
    {
      id: 5,
      labelCabecera: 'Dependencia'
    },
    {
      id: 6,
      labelCabecera: 'Nivel'
    },
    {
      id: 7,
      labelCabecera: 'Calidad'
    },
    {
      id: 8,
      labelCabecera: 'Pts.'
    },
  ];

  const handleSelectRow = (id) => {
    console.log('Seleccionando fila: ' + id);
  };

  const fetchSuggestions = async (query) => {
    //const suggestions = await bodyTable.filter(suggestion => suggestion.nombre === inputValue)
    const suggestions = bodyTable.flatMap(item =>
      Object.values(item)
        .filter(value => 
          value.toString().toLowerCase().includes(query.toLowerCase())
        )
        .map(value => value.toString())
    );
    return suggestions
  };

  const handleSearch = (inputValue) => {
    console.log('Buscando: ', inputValue);
  };

  return (
    <div className={styles.container}>
      <p className={styles.titlePage}>Tabla de resultados de evaluación</p>

      <div className={styles.containerParrafo}>
        <p className={styles.parrafoInstruccionesConIcon}>
          <FontAwesomeIcon icon={faAngleRight} color={'yellow'} /> Instrucciones
        </p>

        <p className={styles.parrafoInstrucciones}>
          Seleccione de la <span>Tabla de resultados de evaluación</span> los acádemicos para los cuales se
          vaya a generar el reporte. Elija los que desee <br /> usando las casillas correspondientes, o todos los de
          la tabla haciendo clic en la casilla de la cabecera. <br />
          También puede realizar búsquedas especificas por nombre, apellido, código, tipo de participación u otros
          datos del académico. <br /> Si se desea hacer búsquedas por nivel (I-IX), habrá que especificarlo de la siguiente
          forma: "n=I" o "n=II", etc. <br />
          Cuando haya terminado, haga clic en "Generar PDF <FontAwesomeIcon icon={faSave} color={'cyan'} />".
        </p>
      </div>

      <div className={styles.containerTable}>
        <div className={styles.containerFiltrosTabla}>
          <div>
            <p className={styles.textoGris}>Buscar:</p>
            <Search 
              placeholder={'Teclee su búsqueda'}
              onGetSuggestions={fetchSuggestions}
              onSearch={handleSearch}
            /> {/* Pendiente pasar las props de este componente */}
          </div>
          <p className={styles.textoGris}>en</p>
          <div>
            <Select/> {/* Pendiente pasar las props de este componente */}
          </div>
        </div>
        <Table cabecerasTable={CABECERAS_TABLA} withHeader={true}>
          {bodyTable.map(dataBody => (
            <tr key={dataBody.id}>
              <td className={`${styles.tdCheckbox}`}>
                <input 
                  type='checkbox'
                  onClick={() => handleSelectRow(dataBody.id)}
                />
              </td>
              <td className={`${styles.td} ${styles.tdTextCenter}`}>{dataBody.id}</td>
              <td className={`${styles.td} ${styles.tdNombre}`}>{dataBody.nombre}</td>
              <td className={`${styles.td} ${styles.tdTextCenter}`}>{dataBody.tipo}</td>
              <td className={`${styles.td} ${styles.tdTextCenter}`}>{dataBody.dependencia}</td>
              <td className={`${styles.td} ${styles.tdTextCenter}`}>{dataBody.nivel}</td>
              <td className={`${styles.td} ${styles.tdTextCenter}`}>{dataBody.calidad}</td>
              <td className={`${styles.td} ${styles.tdTextCenter}`}>{dataBody.pts}</td>
            </tr>
          ))}
        </Table>
      </div>

      <div className={styles.containerButtons}>
        <button
          type='button'
          placeholder='Limpiar'
          className='texto_con_icono'
        >
          Generar PDF <FontAwesomeIcon icon={faSave} color='cyan' />
        </button>
      </div>
    </div>
  )
}

export default ReporteResultadosEvaluacion
