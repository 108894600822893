
import { Footer, Header } from "../../layout";
import { SubirDocumentos } from "../components/VisroDeDocumentos";

export const SubirDoc = () => {
  return (
    <>
      <Header />
      <SubirDocumentos />
      <Footer />
    </>
  );
};
