import { Controller } from 'react-hook-form';
import styles from './styles/Criterio.module.css';

function CriterioInput({ criterio, control, namePrefix }) {
  const { id, nombre, nivel, topeVeces } = criterio.criterio;
  const inputName = `${namePrefix}.${id}`;
  const inputId = `input-${inputName}`;

  // Lista de encabezados por nivel
  const HeadingTag = [`h2`, `h3`, `h4`, `h5`][Math.min(nivel, 2)];

  // Verificar si es un nodo hoja (no tiene subcriterios)
  const isLeafNode = !criterio.subCriterios || criterio.subCriterios.length === 0;

  return (
    <div style={{ marginLeft: nivel * 20 }}>
      {/* Renderizar encabezado semántico */}
      {nivel < 2 ? (
        <HeadingTag className={`titulo-nivel-${nivel}`}>{nombre}</HeadingTag>
      ) : (
        <div>
          <label htmlFor={inputId} className="subcriterio-label">
            {nombre}
          </label>
          <Controller
            name={inputName}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input id={inputId} {...field} className="subcriterio-input" />
            )}
          />
        </div>
      )}

      {/* Renderizar checkbox o input de texto para nodos hoja en el primer nivel */}
      {isLeafNode && nivel === 1 && (
        <Controller
          name={inputName}
          control={control}
          defaultValue=""
          render={({ field }) =>
            topeVeces === 1 ? (
              <input
                type="checkbox"
                id={inputId}
                {...field}
                className="checkbox-input"
              />
            ) : (
              <input
                type="text"
                id={inputId}
                {...field}
                className="text-input"
              />
            )
          }
        />
      )}

      {/* Renderizar subcriterios de forma recursiva */}
      {criterio.subCriterios && criterio.subCriterios.length > 0 && (
        <ul>
          {criterio.subCriterios.map((subCriterio, index) => (
            <CriterioInput
              key={index}
              criterio={subCriterio}
              control={control}
              namePrefix={inputName}
            />
          ))}
        </ul>
      )}
    </div>
  );
}

export default CriterioInput;