import React, { useState, useEffect, useCallback, useMemo } from "react";
import Paginacion from "../../../reutilizable/Paginacion";
import FiltrosMaterias from "./FiltrosMaterias";
import TablaMateriasDetalle from "./TablaMateriasDetalle";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

// Estilos
import styles from "./TablaMaterias.module.css";

// Custom hooks
import useMunicipios from "../../../hooks/useMunicipios";
import useCiclosEscolares from "../../../hooks/useCiclosEscolares";
import useMaterias from "../../../hooks/useMaterias";
import useEditMode from "../../../hooks/useEditMode";
import usePagination from "../../../hooks/usePagination";
import useCartaDesempenoActions from "../../../hooks/useCartaDesempenoActions";
import useEliminarCartaDesempeno from "../../../hooks/useEliminarCartaDesempeno";
import useCicloDisponibilidad from "../../../hooks/useCicloDisponibilidad";
import useMunicipioChange from "../../../hooks/useMunicipioChange";
import useFilteredMaterias from "../../../hooks/useFilteredMaterias";
import useCicloChange from "../../../hooks/useCicloChange";

const TablaMaterias = ({ materias = [], setMaterias, onEliminar, token }) => {
  
  const { municipios } = useMunicipios();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [filteredMaterias, setFilteredMaterias] = useState([]);
  const [materiasState, setMateriasState] = useState(materias); // Ensure this is set up


  // Move usePagination before useCicloChange so setCurrentPage is available
  const {
    currentPage,
    itemsPerPage,
    totalPages,
    currentItems,
    handleFirstPage,
    handleLastPage,
    handlePreviousPage,
    handleNextPage,
    setCurrentPage,
  } = usePagination(filteredMaterias);

  const { selectedCiclo, handleCicloChange } = useCicloChange(setCurrentPage);
  
  const { handleMunicipioChange, municipioError } = useMunicipioChange(
    municipios,
    selectedCiclo,
    filteredMaterias,
    setFilteredMaterias
  );
  const { cicloDisponible, error: cicloError } =
    useCicloDisponibilidad(selectedCiclo);
  const [selectedMunicipio, setSelectedMunicipio] = useState("");
  const { ciclosEscolares, fetchCiclosEscolares } = useCiclosEscolares();
  const { fetchMaterias } = useMaterias(
    setMaterias,
    selectedCiclo,
    setFilteredMaterias
  );
  const {
    editMode,
    changes,
    savedChanges,
    toggleEditMode,
    handleInputChange,
    handleSaveChanges,
    handleConsultarPDF,
  } = useEditMode(filteredMaterias, setFilteredMaterias);
  
  const { handleGenerarOActualizarPDF } = useCartaDesempenoActions(
    municipios,
    filteredMaterias,
    setFilteredMaterias,
    changes
  );
  const { handleEliminar } = useEliminarCartaDesempeno(
    filteredMaterias,
    setFilteredMaterias
  );
  const { filteredAndSearchedMaterias, handleSearchChange, search } =
    useFilteredMaterias(filteredMaterias);

  useEffect(() => {
    if (ciclosEscolares.length === 0) {
      fetchCiclosEscolares();
    }
  }, [ciclosEscolares, fetchCiclosEscolares]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedMaterias = [...filteredMaterias].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setFilteredMaterias(sortedMaterias);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? <FaArrowUp /> : <FaArrowDown />;
    }
    return null;
  };

  const handleMunicipioChangeWrapper = (e) => {
    const newMunicipio = e.target.value;
    setSelectedMunicipio(newMunicipio);
    handleMunicipioChange(newMunicipio);
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.filter} ${styles.marginBottom}`}>
        <label htmlFor="cicloEscolar">CICLO ESCOLAR: </label>
        <select
          id="cicloEscolar"
          value={selectedCiclo}
          onChange={handleCicloChange}
          disabled={!cicloDisponible}
        >
          <option value="">Seleccione un ciclo escolar</option>
          {ciclosEscolares.length > 0 ? (
            ciclosEscolares.map((ciclo) => (
              <option key={ciclo} value={ciclo}>
                {ciclo}
              </option>
            ))
          ) : (
            <option value="" disabled>
              No hay ciclos disponibles
            </option>
          )}
        </select>
      </div>
      {selectedCiclo && (
        <>
          <div className={`${styles.filterRow}`}>
            <FiltrosMaterias
              searchTerm={search}
              handleSearchChange={handleSearchChange}
              municipios={municipios}
              selectedMunicipio={selectedMunicipio}
              handleMunicipioChange={handleMunicipioChangeWrapper}
              cicloDisponible={cicloDisponible}
            />
          </div>

          <TablaMateriasDetalle
            currentItems={currentItems}
            editMode={editMode}
            municipios={municipios}
            changes={changes}
            cicloDisponible={cicloDisponible}
            handleInputChange={handleInputChange}
            toggleEditMode={toggleEditMode}
            handleGenerarOActualizarPDF={handleGenerarOActualizarPDF}
            handleConsultarPDF={handleConsultarPDF}
            handleEliminar={handleEliminar}
            getSortIcon={getSortIcon}
            handleSort={handleSort}
            isEliminarDisabled={(materia) => materia.marcado === "0"}
          />

          <Paginacion
            currentPage={currentPage}
            totalPages={totalPages}
            onFirstPage={handleFirstPage}
            onLastPage={handleLastPage}
            onPreviousPage={handlePreviousPage}
            onNextPage={handleNextPage}
          />
        </>
      )}
    </div>
  );
};

export default TablaMaterias;
