import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useSearchStore = create(
    persist(
        (set, get) => ({
            academicos: [],
            setAcademicos: (array) => set({ academicos: array }),
            clearSearch: () => set({ academicos: [] }),
        }),
        { name: 'search-storage' }, // Optional: name for persistence  
    )
);
