import React from 'react';
import QRValidator from '../components/QRValidator';

const QRValidatorPage = () => {
  return (
    <div>
      <QRValidator />
    </div>
  );
};

export default QRValidatorPage;