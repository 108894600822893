import axios from "axios";
import { getAccessToken } from "./authService";  // Asumimos que tienes el servicio de autenticación separado

// Obtener la URL base desde las variables de entorno
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Concatenar el contexto y el recurso para las materias y ciclos escolares
const API_MATERIAS_URL = `${API_BASE_URL}/api/v1/jefe_depto/lista-materias`;
const API_CICLOS_ESCOLARES_URL = `${API_BASE_URL}/api/v1/jefe_depto/lista-ciclos-escolares`;

// Servicio para obtener la lista de materias
export const obtenerMaterias = async (codigoEmpleado, cicloEscolar, app) => {
  try {
    // Obtener o renovar el access token
    const token = await getAccessToken(); 

    const response = await axios.post(
      API_MATERIAS_URL,
      {
        codigoEmpleado,
        cicloEscolar,
        app,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Añadimos el token a los headers
        },
      }
    );
    return response.data;  // Devolvemos los datos de la respuesta
  } catch (error) {
    console.error("Error al obtener las materias:", error);
    throw new Error("No se pudo obtener la lista de materias.");
  }
};

// Servicio para filtrar materias por ciclo escolar
export const filtrarMateriasPorCiclo = async (cicloEscolar) => {
  try {
    // Aquí podrías obtener el código del empleado desde algún estado si lo necesitas
    const materias = await obtenerMaterias("XXSAFS3", cicloEscolar, "Prueba");
    return materias.filter((materia) => materia.cicloEscolar === cicloEscolar);
  } catch (error) {
    console.error("Error al filtrar materias:", error);
    throw new Error("No se pudo filtrar la lista de materias.");
  }
};

// Servicio para obtener la lista de ciclos escolares
export const obtenerCiclosEscolares = async () => {
  try {
    const token = await getAccessToken(); // Obtener o renovar el access token
    const response = await axios.get(API_CICLOS_ESCOLARES_URL, {
      headers: {
        Authorization: `Bearer ${token}`, // Se añade el token para autenticación
      },
    });
    return response.data; // Devolvemos los datos de la respuesta
  } catch (error) {
    console.error("Error al obtener los ciclos escolares:", error);
    throw new Error("No se pudo obtener la lista de ciclos escolares.");
  }
};
