import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faSave } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import Alert from '../../../reutilizable/Alert';
import { getRequisitos, postSatisfacerRequisitos } from '../services/requisitosService';
import { useEvaluationStore } from '../../../store/useEvaluationStore';
import styles from './styles/requisitos.module.css';

export const Requisitos = () => {
  const { selectedDataAcademico } = useEvaluationStore();

  const [dataForm, setDataForm] = useState({});
  const [requisitos, setRequisitos] = useState([]);
  const [showTextArea, setShowTextArea] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [cumpleRequisitos, setCumpleRequisitos] = useState({
    cumple: Boolean,
    mensaje: ''
  });

  useEffect(() => {
    const idSolicitud = selectedDataAcademico.id;
    const aliasActividad = 'evaluacion'; // se tiene que mapear
    getRequisitos(idSolicitud, aliasActividad)
      .then(response => {
        setDataForm(response.data)
        setRequisitos(response.data.requisitos)
        setError(null)
      })
      .catch(error => {
        console.error('Error al obtener los requisitos: ', error)
        if (error) {
          setError('Ocurrió un error inesperado, no se ha podido obtener los requisitos.', error);
        }

        if (error.response) {
          setError(`Ocurrió un error al obtener los requisitos: ${error.response.data.mensaje} - Status Code: ${error.response.status}`);
        }
      })
      .finally(() => { setLoading(false) })
  }, [selectedDataAcademico])

  const { register, handleSubmit, setValue, getValues, watch, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      esConcursante: false,
      ratificaModifica: null,
      razones: null,
      requisitos: [],
      respuestaInconformidad: null
    }
  });

  // Observar los valores para mostrar condicionalmente los inputs
  const esConcursante = watch('esConcursante');
  const ratificaModifica = watch('ratificaModifica');

  // Autorellenar el formulario
  useEffect(() => {
    if (dataForm) {
      dataForm?.requisitos?.forEach((requisito) => {
        setValue(`requisitos.${requisito.id}`, requisito.satisfecho);
      });

      setValue('esConcursante', dataForm.esConcursante);
      setValue('ratificaModifica', dataForm.ratificaModifica);
      setValue('razones', dataForm.razones);
      setValue('respuestaInconformidad', dataForm.respuestaInconformidad);
    }
  }, [dataForm, setValue, requisitos])

  useEffect(() => {
    (esConcursante === 'false')
      ? setShowTextArea(true)
      : setShowTextArea(false);
  }, [esConcursante, showTextArea]);

  const handleClickMarcarTodos = () => {
    requisitos?.forEach((requisito) => {
      if (!getValues(`requisitos.${requisito.id}`)) {
        setValue(`requisitos.${requisito.id}`, true);
      }
    });
  };

  //console.log(selectedDataAcademico)

  // Arreglo para mapear los indices de los requisitos para adaptar al formato que espera el request
  const desiredKeys = ["11", "12", "13", "14", "15", "16", "17", "18", "19", "20"];
  const handleSubmitRequisitos = async (data) => {
    const dataUserSesion = JSON.parse(localStorage.getItem('auth-storage')); // Se extrae la informacion del usuario que tiene la sesion abierta
    const aliasRol = dataUserSesion.state.userInfo.roles[0];
    const aliasActividad = 'evaluacion';
    const esConcursante = data.esConcursante;
    const razones = data.razones || null;
    const idSolicitud = 1 || '';
    const requisitos = data?.requisitos?.reduce((acc, value, index) => {
      console.log(acc[String(index)])
      if (value === true) {
        acc[String(index)] = "satisfecho"; // Convierte el índice a string
      }
      return acc;
    }, {});

    //console.log(requisitos)
    const requisitosJson = JSON.stringify(requisitos)
    console.log(requisitosJson)

    const body = { aliasRol, aliasActividad, esConcursante, razones, idSolicitud, requisitos };
    console.log(body)

    const response = await postSatisfacerRequisitos(JSON.stringify(body));
    console.log(response)
    if (response.cumpleRequisitos) {
      setIsVisible(!isVisible);
      setCumpleRequisitos({
        cumple: true,
        mensaje: 'Cambios guardados. Ya puede realizar la evaluación'
      })
    } else {
      setCumpleRequisitos({
        cumple: false,
        mensaje: 'Cambios guardados. No cumple con los requisitos de evaluación y no puede ser evaluado'
      })
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => { setIsVisible(false) }, 2000); 
    return () => clearTimeout(timer); // Limpia el temporizador al desmontar
  }, [isVisible])

  if (loading) {
    return <Alert typeAlert='warning'><p>Cargando requisitos...</p></Alert>
  }

  if (error) {
    return <Alert typeAlert='error'><p>{error}</p></Alert>;
  }

  return (
    <>
      <div className={styles.requisitosContainer}>
        <h2>
          <FontAwesomeIcon icon={faAngleRight} color={'yellow'} /> {''}
          Requisitos de participación
        </h2>

        <div className={styles.listRequisitos}>
          <form onSubmit={handleSubmit(handleSubmitRequisitos)}>
            {requisitos?.map((requisito, index) => (
              <div className={styles.requisito} key={requisito.id}>
                <div className={styles.nombreRequisito}>
                  <label htmlFor={`requisito-${requisito.id}`} className=''>
                    <span>{requisito.romano}</span> {requisito.nombre}
                  </label>
                </div>
                <div>
                  <input
                    id={`requisito-${requisito.id}`}
                    type='checkbox'
                    {...register(`requisitos.${requisito.id}`)}
                    className='checkbox_green'
                    defaultChecked={requisito.satisfecho}
                  />
                </div>
              </div>
            ))}

            <div className={styles.restForm}>
              <div className={styles.buttonCheckTodos}>
                <button
                  type='button'
                  onClick={handleClickMarcarTodos}
                >[Marcar Todos]</button>
              </div>

              <div className={styles.inputsRadio}>
                <div className={styles.containerInputRadio}>
                  <label htmlFor='concursante'>Concursante</label>
                  <input
                    type='radio'
                    name='concursante'
                    id='concursante'
                    className='inpt_radio_green'
                    value={true}
                    {...register('esConcursante')}
                    onChange={() => setValue("esConcursante", true)}
                    checked={esConcursante === true}
                  />
                </div>
                <div className={styles.containerInputRadio}>
                  <label htmlFor='noConcursante'>No concursante</label>
                  <input
                    type='radio'
                    name='concursante'
                    id='noConcursante'
                    className='inpt_radio_green'
                    value={false}
                    {...register('esConcursante')}
                    onChange={() => setValue("esConcursante", false)}
                    checked={esConcursante === false}
                  />
                </div>
              </div>

              {esConcursante === false && (
                <>
                  <div className={styles.textarea}>
                    <label htmlFor='razones'>Razones:</label>
                    <textarea
                      name="razones"
                      id="razones"
                      rows={4}
                      className={styles.textarea}
                      {...register('razones', { minLength: 10 })} // preguntar el minimo de caracteres
                    ></textarea>
                  </div>

                  <div className={styles.inputsRadio}>
                    <div className={styles.containerInputRadio}>
                      <label htmlFor='ratifica'>Ratifica</label>
                      <input
                        type='radio'
                        name='ratifica'
                        id='ratifica'
                        className='inpt_radio_green'
                        value={'ratifica'}
                        checked={ratificaModifica === 'ratifica'}
                        {...register('ratificaModifica')}
                      />
                    </div>
                    <div className={styles.containerInputRadio}>
                      <label htmlFor='modifica'>Modifica</label>
                      <input
                        type='radio'
                        name='modifica'
                        id='modifica'
                        className='inpt_radio_green'
                        value={'modifica'}
                        checked={ratificaModifica === 'modifica'}
                        {...register('ratificaModifica')}
                      />
                    </div>
                  </div>

                  <div className={styles.textarea}>
                    <label htmlFor='razones'>Razones:</label>
                    <textarea
                      name="razones"
                      id="razones"
                      rows={4}
                      className={styles.textarea}
                      {...register('respuestaInconformidad')} // preguntar el minimo de caracteres
                    ></textarea>
                  </div>
                </>
              )}

              {isSubmitSuccessful && cumpleRequisitos && (
                <Alert typeAlert={cumpleRequisitos.cumple ? 'success' : 'error' }>
                  <p>{cumpleRequisitos.mensaje}</p>
                </Alert>
              )}

              {errors.requisitos && (
                <Alert typeAlert={'warning'}>
                  <p>Debe seleccionar al menos un requisito</p>
                </Alert>
              )}

              {errors.esConcursante && (
                <Alert typeAlert={'error'}>
                  <p>Debe seleccionar al menos un requisito e indicar si es o no concursante</p>
                </Alert>
              )}

              <div className={styles.containerSubmit}>
                <button type='submit' className='texto_con_icono'>
                  Guardar {''}
                  <FontAwesomeIcon icon={faSave} color={'cyan'} />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
