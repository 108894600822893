import React from "react";
import styles from "./TablaMateriasDetalle.module.css";

const TablaMateriasDetalle = ({
  currentItems,
  editMode,
  municipios,
  changes,
  cicloDisponible,
  handleInputChange,
  toggleEditMode,
  handleGenerarOActualizarPDF,
  handleConsultarPDF,
  handleEliminar,
  getSortIcon,
  handleSort,
}) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th onClick={() => handleSort("codigoProfesor")}>
            CÓDIGO PROFESOR {getSortIcon("codigoProfesor")}
          </th>
          <th onClick={() => handleSort("nombreProfesor")}>
            NOMBRE DOCENTE {getSortIcon("nombreProfesor")}
          </th>
          <th onClick={() => handleSort("asignatura")}>
            MATERIA {getSortIcon("asignatura")}
          </th>
          <th onClick={() => handleSort("crn")}>CRN {getSortIcon("crn")}</th>
          <th onClick={() => handleSort("clave")}>CLAVE {getSortIcon("clave")}</th>
          <th onClick={() => handleSort("programaEducativo")}>
            PROGRAMA EDUCATIVO {getSortIcon("programaEducativo")}
          </th>
          <th onClick={() => handleSort("cargaHoraria")}>
            CARGA HORARIA {getSortIcon("cargaHoraria")}
          </th>
          <th>NO. OFICIO</th>
          <th>CALIFICACIÓN</th>
          <th>MUNICIPIO</th>
          <th>CICLO ESCOLAR</th>
          <th>GENERAR</th>
          <th>DESCARGAR</th>
          <th>EDITAR</th>
          <th>ELIMINAR</th>
        </tr>
      </thead>
      <tbody>
        {currentItems.length > 0 ? (
          currentItems.map((materia, index) => (
            <tr key={index}>
              <td>{materia.codigoProfesor}</td>
              <td>{materia.nombreProfesor}</td>
              <td>{materia.asignatura}</td>
              <td>{materia.crn}</td>
              <td>{materia.clave}</td>
              <td>{materia.programaEducativo}</td>
              <td>{materia.cargaHoraria}</td>
              <td>
                {editMode[index] ? (
                  <input
                    type="text"
                    value={materia.noOficio}
                    onChange={(e) => handleInputChange(index, "noOficio", e.target.value)}
                  />
                ) : (
                  materia.noOficio
                )}
              </td>
              <td>
                {editMode[index] ? (
                  <select
                    value={materia.calificacion}
                    onChange={(e) => handleInputChange(index, "calificacion", e.target.value)}
                  >
                    <option value="">Seleccione una calificación</option>
                    <option value="Bueno">Bueno</option>
                    <option value="Excelente">Excelente</option>
                    <option value="No Aplica">No Aplica</option>
                  </select>
                ) : (
                  materia.calificacion
                )}
              </td>
              <td>
                {editMode[index] ? (
                  <select
                    value={materia.ubicacion || ""}
                    onChange={(e) => handleInputChange(index, "ubicacion", e.target.value)}
                  >
                    <option value="">Seleccione una ubicación</option>
                    {municipios.map((municipio) => (
                      <option key={municipio.id} value={municipio.municipio}>
                        {municipio.municipio}
                      </option>
                    ))}
                  </select>
                ) : (
                  materia.ubicacion || "No Disponible"
                )}
              </td>
              <td>{materia.cicloEscolar}</td>
              <td>
                {(materia.marcado === "1" && (materia.statusMunicipio === "0" || changes[index])) ? (
                  <button
                    className={`${styles.button} ${styles.buttonChanged}`}
                    onClick={() => handleGenerarOActualizarPDF(materia, index)}
                  >
                    Actualizar PDF
                  </button>
                ) : materia.marcado === "0" ? (
                  <button
                    className={styles.button}
                    onClick={() => handleGenerarOActualizarPDF(materia, index)}
                    disabled={!materia.noOficio || !materia.calificacion || !materia.ubicacion}
                  >
                    Generar Nuevo PDF
                  </button>
                ) : null}
              </td>
              <td>
                {materia.marcado === "0" ? null : (
                  <button
                    className={`${styles.button}`}
                    onClick={() => handleConsultarPDF(materia.idQr)}
                  >
                    Descargar PDF
                  </button>
                )}
              </td>
              <td>
                <button
                  className={`${styles.button}`}
                  onClick={() => toggleEditMode(index)}
                  disabled={!cicloDisponible}
                >
                  {editMode[index] ? "Guardar" : "Editar"}
                </button>
              </td>
              <td>
                <button
                  className={`${styles.button} ${
                    materia.marcado === "0" ? styles.buttonDisabled : ""
                  }`}
                  onClick={() => handleEliminar(materia.idQr, index)}
                  disabled={materia.marcado === "0" || !cicloDisponible}
                >
                  Eliminar
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="15">No hay materias disponibles para el ciclo seleccionado</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TablaMateriasDetalle;
