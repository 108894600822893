import React, { useState, useEffect } from "react";
import { obtenerMaterias } from "../../materiasService";
import TablaMaterias from "./TablaMaterias";
import styles from "./CartaDesempeñoDocente.module.css";

const CartaDesempeñoDocente = () => {
  const [materias, setMaterias] = useState([]);

  // Fetch materias al montar el componente
  useEffect(() => {
    const fetchMaterias = async () => {
      try {
        const data = await obtenerMaterias("XXSAFS3", "", "Prueba");
        setMaterias(data);
      } catch (error) {
        console.error("Error al obtener las materias:", error);
      }
    };
    fetchMaterias();
  }, []);

  return (
    <div>
      <article>
        <header>
          <h3 className={styles.constancia_title}>
             Carta de desempeño docente
          </h3>
        </header>

        <TablaMaterias
          materias={materias}
          setMaterias={setMaterias}
          onGenerarPDF={(materia) => console.log("Generar PDF", materia)}
          onDescargarPDF={(materia) => console.log("Descargar PDF", materia)}
          onEliminar={(index) =>
            setMaterias(materias.filter((_, i) => i !== index))
          }
        />
      </article>
    </div>
  );
};

export default CartaDesempeñoDocente;
