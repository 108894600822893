import React, { useState } from "react";
import { logout } from "../../../../features/authService";  // Importar la función de logout
import { useNavigate } from 'react-router-dom';  // Para redirigir al usuario
import styles from "./HeaderUser.module.css";

const HeaderUserOptions = () => {
  const navigate = useNavigate();  // Para manejar la redirección

  const handleLogout = () => {
    logout();  // Llamar a la función de logout
    navigate('/login');  // Redirigir al usuario a la página de login después de cerrar sesión
  };

  const options = [
    {
      label: "Cerrar sesión",
      action: handleLogout,
    },
  ];

  return (
    <div className={styles.header_user_options}>
      {options.map((option, index) => (
        <div
          className={styles.header_user_option}
          key={index}
          onClick={option.action}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

const HeaderUser = () => {
  const [isVisibleOptions, setIsVisibleOptions] = useState(false);
  
  return (
    <div
      className={styles.header_user}
      onClick={() => setIsVisibleOptions(!isVisibleOptions)}
    >
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 24 24"
        width="24px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="User">
          <g>
            <path d="M17.438,21.937H6.562a2.5,2.5,0,0,1-2.5-2.5V18.61c0-3.969,3.561-7.2,7.938-7.2s7.938,3.229,7.938,7.2v.827A2.5,2.5,0,0,1,17.438,21.937ZM12,12.412c-3.826,0-6.938,2.78-6.938,6.2v.827a1.5,1.5,0,0,0,1.5,1.5H17.438a1.5,1.5,0,0,0,1.5-1.5V18.61C18.938,15.192,15.826,12.412,12,12.412Z"></path>
            <path d="M12,9.911a3.924,3.924,0,1,1,3.923-3.924A3.927,3.927,0,0,1,12,9.911Zm0-6.847a2.924,2.924,0,1,0,2.923,2.923A2.926,2.926,0,0,0,12,3.064Z"></path>
          </g>
        </g>
      </svg>
      nombre apellido
      <span className={styles.header_user_options_container}>
        {isVisibleOptions && <HeaderUserOptions />}
      </span>
    </div>
  );
};

export default HeaderUser;
