import { Menu, useMenu } from "../../../../reutilizable/Menu";
import { Footer, Header } from "../../../layout";
import { Instrucciones } from "../../instrucciones/components/Instrucciones";
import { LlenarSolicitudComponent } from "../components/LlenarSolicitudComponent";
import styles from "./llenarSolicitud.module.css";

export const LlenarSolicitudPage = () => {
  const menu = useMenu(
    [
      {
        label: "Instrucciones",
        element: <Instrucciones />,
      },
      {
        label: "Llenar solicitud",
        element: <LlenarSolicitudComponent />,
      },
    ],
    {
      selectedValue: "Instrucciones",
      isVertical: false,
    }
  );

  return (
    <>
      <div>
        <Header />
        <Menu menu={menu} />
        <div className={styles.mainContainer}>{menu.element}</div>
        <Footer />
      </div>
    </>
  );
};
