import { useState, useEffect } from 'react';
import { obtenerCiclosEscolares } from "../features/materiasService";

const useCiclosEscolares = () => {
  const [ciclosEscolares, setCiclosEscolares] = useState([]);

  const fetchCiclosEscolares = async () => {
    try {
      const data = await obtenerCiclosEscolares();
      const sortedData = data.sort((a, b) => {
        const yearA = parseInt(a.substring(0, 4));
        const yearB = parseInt(b.substring(0, 4));
        if (yearA !== yearB) {
          return yearA - yearB;
        }
        return a.slice(-1).localeCompare(b.slice(-1));
      });
      setCiclosEscolares(sortedData);
    } catch (error) {
      console.error("Error al obtener los ciclos escolares:", error);
    }
  };

  useEffect(() => {
    fetchCiclosEscolares();
  }, []);

  return { ciclosEscolares, fetchCiclosEscolares };
};

export default useCiclosEscolares;