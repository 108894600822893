import styles from "./instrucciones.module.css";

export const InstruccionesComponent = () => {
  return (
    <>
      <div className={styles.instrucciones_cnntainer}>
        <p className={styles.instrucciones_pagraph}>
          Esse eu mollit quis dolor ut voluptate enim. Nulla do aliqua laboris
          irure aliquip tempor ut cupidatat pariatur officia. Voluptate duis
          nulla ut est fugiat irure consequat in ex. Culpa est deserunt cillum
          eiusmod nisi minim. Voluptate et nulla excepteur voluptate ex.
          Reprehenderit ex quis quis est pariatur amet. Ex officia esse laboris
          laboris cupidatat ad.
        </p>
        <p className={styles.instrucciones_pagraph}>
          Esse eu mollit quis dolor ut voluptate enim. Nulla do aliqua laboris
          irure aliquip tempor ut cupidatat pariatur officia. Voluptate duis
          nulla ut est fugiat irure consequat in ex. Culpa est deserunt cillum
          eiusmod nisi minim. Voluptate et nulla excepteur voluptate ex.
          Reprehenderit ex quis quis est pariatur amet. Ex officia esse laboris
          laboris cupidatat ad.
        </p>
        <p className={styles.instrucciones_pagraph}>
          Esse eu mollit quis dolor ut voluptate enim. Nulla do aliqua laboris
          irure aliquip tempor ut cupidatat pariatur officia. Voluptate duis
          nulla ut est fugiat irure consequat in ex. Culpa est deserunt cillum
          eiusmod nisi minim. Voluptate et nulla excepteur voluptate ex.
          Reprehenderit ex quis quis est pariatur amet. Ex officia esse laboris
          laboris cupidatat ad.
        </p>
        <p className={styles.instrucciones_pagraph}>
          Esse eu mollit quis dolor ut voluptate enim. Nulla do aliqua laboris
          irure aliquip tempor ut cupidatat pariatur officia. Voluptate duis
          nulla ut est fugiat irure consequat in ex. Culpa est deserunt cillum
          eiusmod nisi minim. Voluptate et nulla excepteur voluptate ex.
          Reprehenderit ex quis quis est pariatur amet. Ex officia esse laboris
          laboris cupidatat ad.
        </p>
      </div>
    </>
  );
};
