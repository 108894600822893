import axios from 'axios';
import { getAccessToken } from '../../authService';  // Asumimos que tienes el servicio de autenticación separado

// Obtener la URL base desde las variables de entorno
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// Concatenar el contexto y el recurso para las materias
const API_DATOS_PARTICIPANTE_URL = `${API_BASE_URL}/api/v1/evaluacion/datos-participante`;

export const getDatosParticipante = async (codigo) => {
  try {
    const token = await getAccessToken();
    const response = await axios.get(`${API_DATOS_PARTICIPANTE_URL}?codigo=${codigo}`, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
    return response;
  } catch (error) {
    console.error('Error getting datos del participante: ', error);
    throw error;
  }
};