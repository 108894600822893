import styles from "./footer.styles.module.css";
import udgWhite from "../../../../assets/images/UDG_white.png";
import facebook from "../../../../assets/images/facebook.png";
import instagram from "../../../../assets/images/instagram.png";
import x from "../../../../assets/images/x.png";
import youtube from "../../../../assets/images/youtube.png";
import tiktok from "../../../../assets/images/tiktok.png";

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.logo_section}>
        <img src={udgWhite} alt="logo UDG" className={styles.udg_logo} />
        <div className={styles.footer_contact}>
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="facebook" className={styles.social} />
          </a>
          <label className={styles.socialSpacing}> | </label>
          <a
            href="https://www.x.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={x} alt="x" className={styles.social} />
          </a>
          <label className={styles.socialSpacing}> | </label>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="instagram" className={styles.social} />
          </a>
          <label className={styles.socialSpacing}> | </label>

          <a
            href="https://www.youtube.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youtube} alt="youtube" className={styles.social} />
          </a>
          <label className={styles.socialSpacing}> | </label>
          <a
            href="https://www.tiktok.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={tiktok} alt="tiktok" className={styles.social} />
          </a>
        </div>
      </div>
      <div className={styles.footer_content}>
        <div className={styles.footer_info}>
          <p>VICERRECTORÍA EJECUTIVA</p>
          <p>Coordinación General Académica y de Innovación</p>
          <p>
            Av. Juárez No. 976, Piso 8, Colonia Centro, C.P. 44100, Guadalajara,
            Jalisco, México
          </p>
          <p>Teléfono: +52 (33) 31342200 Ext. 11960</p>
          <p>E-mail: proesde@udg.mx</p>
        </div>
      </div>
      <p className={styles.copyright}>
        Derechos Reservados ©1997 - 2019. Universidad de Guadalajara. Sitio
        desarrollado por CGTI | Créditos de sitio | Política de privacidad y
        manejo de datos
      </p>
    </footer>
  );
};
