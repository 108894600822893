import axios from 'axios';
import { getAccessToken } from '../../authService';

// Obtener la URL base desde las variables de entorno
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_DATOS_EVALUAR_URL = `${API_BASE_URL}/api/v1/evaluacion/datos-evaluar?idSolicitud=${1}&aliasActividad=${'evaluacion'}`;
const API_OBTENER_COMENTARIOS_URL = `${API_BASE_URL}/api/v1/evaluacion/obtener-comentarios?idSolicitud=1`;

export const datosEvaluacion = async () => {
  try {
    const token = await getAccessToken();
    const response = await axios(API_DATOS_EVALUAR_URL, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
    return response;
  } catch (error) {
    console.error('Error getting datos de evaluacion: ', error);
    throw error;
  }
};
