import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles/MensajeEvaluacion.module.css';
import { obtenerAnios } from '../../../utils';

const MensajeEvaluacion = () => {

    const { anioActual, anioSiguiente } = obtenerAnios();

    return (
        <div className={styles.container}>
            <p className={styles.titulo}>
                <FontAwesomeIcon icon={faChevronRight} className={styles.icon} />{' '}
                Programa para estimulos al desempeño docente {anioActual} - {anioSiguiente}
            </p>

            <div className={styles.texto}>
                <p>
                    Bienvenido al módulo de evaluación <br/>
                    <span>Instrucciones</span>
                </p>
            </div>
        </div>
    )
}

export default MensajeEvaluacion
