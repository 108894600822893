export const miembros = {
    presidente: {
        tratamiento: "Dr.",
        nombre: "Carlos Gómez",
        departamento: "Departamento de Mecánica",
        cargo: {
            descripcion: "Presidente"
        }
    },
    secretario: {
        tratamiento: "Mtro.",
        nombre: "Laura Sánchez",
        departamento: "Departamento de Ingeniería Eléctrica",
        cargo: {
            descripcion: "Secretario"
        }
    },
    vocal1: {
        tratamiento: "Ing.",
        nombre: "Miguel Herrera",
        departamento: "Departamento de Ingeniería Civil",
        cargo: {
            descripcion: "Vocal 1"
        }
    },
    vocal2: {
        tratamiento: "Dra.",
        nombre: "Ana Morales",
        departamento: "Departamento de Ingeniería Industrial",
        cargo: {
            descripcion: "Vocal 2"
        }
    },
    vocal3: {
        tratamiento: "Lic.",
        nombre: "Fernando López",
        departamento: "Departamento de Ingeniería Electrónica",
        cargo: {
            descripcion: "Vocal 3"
        }
    },
    vocal4: {
        tratamiento: "Ing.",
        nombre: "Patricia Ramírez",
        departamento: "Departamento de Computación",
        cargo: {
            descripcion: "Vocal 4"
        }
    },
    externo1: {
        tratamiento: "Dr.",
        nombre: "Gabriel Núñez",
        departamento: "Departamento Externo",
        cargo: {
            descripcion: "Externo 1"
        }
    },
    externo2: {
        tratamiento: "Mtro.",
        nombre: "Claudia Ortega",
        departamento: "Departamento Externo",
        cargo: {
            descripcion: "Externo 2"
        }
    }
};
