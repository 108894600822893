import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrush, faSave } from '@fortawesome/free-solid-svg-icons';
import AsideInstrucciones from './AsideInstrucciones';
import Alert from '../../../reutilizable/Alert';
import styles from './styles/ReporteActaEvaluaciones.module.css';

// Obtener la URL base desde las variables de entorno
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Concatenar el contexto y el servicio/recurso
const API_URL = `${API_BASE_URL}/api/v1/acta-reporte-evaluados`;
const API_URL_MUNICIPIOS = `${API_BASE_URL}/api/v1/dependencia/municipio/all`;
const API_URL_COMISION = `${API_BASE_URL}/api/v1/comision/comision-usuario`;

const formatDate = (date, isEndDate = false) => {
  if (!date) return null;
  const time = isEndDate ? "23:59:59.999" : "00:00:00.000";
  return `${date} ${time}`;
};

const ReporteActaEvaluaciones = () => {
  const { handleSubmit, reset, register, watch, formState: { errors } } = useForm({
    defaultValues: {
      numActa: '',
      horaFecha: '',
      lugarReunion: '',
      horaTerminacion: '',
      tipoFechaImpresion: '',
      fechaDesde: '',
      fechaHasta: '',
      municipio: '',
      fechaReunion: ''
    }
  });

  const [isActivoPeriodoFecha, setIsActivoPeriodoFecha] = useState(false);
  const [municipios, setMunicipios] = useState([]);
  const [comsion, setComision] = useState("")


  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    axios.get(API_URL_MUNICIPIOS, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        setMunicipios(response.data.municipios)
      })
      .catch(error => console.error("Error fetching grados: ", error))
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    axios.get(API_URL_COMISION, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        setComision(response.data)
      })
      .catch(error => console.error("Error fetching grados: ", error))
  }, [])



  const handleClickLimpiar = () => reset();

  const hasErrors = Object.keys(errors).length > 0;

  const handleSubmitActaEvaluaciones = async (data) => {   
    const [year, month, day] = data.fechaReunion.split('-');

    const monthsInSpanish = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];
    const mes = monthsInSpanish[parseInt(month, 10) - 1]; 


    const body = {
        acta: data.numActa,
        hora: data.horaFecha,
        dia: day.padStart(2, '0'), 
        mes: mes.charAt(0).toUpperCase() + mes.slice(1),
        anio: year,
        sala: data.lugarReunion,
        horasfin: data.horaTerminacion,
        inicioDate: formatDate(data.fechaDesde) || null,
        finDate: formatDate(data.fechaHasta, true) || null,
        sede: data.municipio
    };

    console.log("body", body);

    try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.post(API_URL, body, {
            headers: {
                Accept: '*/*',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob'
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
    } catch (error) {
        console.error('Error during submission:', error);
        throw new Error('Please check the data');
    }
};


  const periodoFecha = watch('tipoFechaImpresion');
  useEffect(() => {
    const activoPeriodoFecha = periodoFecha === 'periodo';
    if (activoPeriodoFecha) {
      setIsActivoPeriodoFecha(true);
    } else {
      setIsActivoPeriodoFecha(false);
    }
  }, [isActivoPeriodoFecha, periodoFecha])

  return (
    <div className={styles.container}>
      <div className={styles.containerAside}>
        <AsideInstrucciones />
      </div>

      <div className={styles.containerContent}>
        <div>
          <h2 className={styles.titulo}>IMPRIMIR ACTA DE EVALUACIONES</h2>
        </div>

        <div className={styles.parafoInfo}>
          <form action="" onSubmit={handleSubmit(handleSubmitActaEvaluaciones)}>
            <div>
              <p className={styles.tituloParrafo}>
                <input
                  type="text"
                  name="numActa"
                  {...register('anumActa', { required: true })}
                  id="numActa"
                  placeholder='1a'
                  className={errors.acta ? `${styles.inputError} ${styles.inputSmall}` : `${styles.input} ${styles.inputSmall}`}
                />
                ACTA DE ASIGNACIÓN DE NIVEL
              </p>

              <p className={styles.parrafo_info}>
                Siendo las
                <input
                  type="text"
                  name="horaFecha"
                  {...register('horaFecha', { required: true })}
                  id="horaFecha"
                  placeholder='hh:mm'
                  className={errors.horaFecha ? `${styles.inputError} ${styles.inputSmall}` : `${styles.input} ${styles.inputSmall}`}
                /> hrs. del día
                <input
                  type="date"
                  name="fechaReunion"
                  id="fechaReunion"
                  className={styles.input}
                  {...register('fechaReunion')}
                />
                , en
                <input
                  type="text"
                  name="lugarReunion"
                  {...register('lugarReunion', { required: true })}
                  id="lugarReunion"
                  placeholder='<la sala de juntas>'
                  className={errors.lugarReunion ? `${styles.inputError}` : `${styles.input}`}
                />
                , ubicada en
                <select
                  id="municipio"
                  {...register('municipio', { required: true })}
                  className={errors.municipio ? `${styles.input_error} ${styles.input}` : styles.input}
                >
                  <option value="">Seleccione</option>
                  {municipios.map((municipio, index) => (
                    <option key={index} value={municipio}>
                      {municipio === "CihuatlÃ¡n" ? "Cihuatlán" : municipio}
                    </option>
                  ))}
                </select>


                , Jalisco, se reunieron miembros de la {comsion.replace(/\(.*?\)/g, '').trim() + " "}
                del Programa de Estímulos del Departamento Docente Promoción 2024-2025, para la evaluación de expedientes.
              </p>

              <p>
                La reunión se dio por terminada a las
                <input
                  type="text"
                  name="horaTerminacion"
                  {...register('horaTerminacion', { required: true })}
                  id="horaTerminacion"
                  placeholder='hh:mm'
                  className={errors.horaTerminacion ? `${styles.inputError} ${styles.inputSmall}` : `${styles.input} ${styles.inputSmall}`}
                />hrs. del presente
              </p>
            </div>

            <div className={styles.containerOpciones}>
              <p>Hay dos opciones para imprimir el reporte de los expedientes evaluados:</p>

              <div>
                <div className={styles.inputRadio}>
                  <input
                    type='radio'
                    id='hoy'
                    value='hoy'
                    {...register('tipoFechaImpresion', { required: true })}
                    className={errors.tipoFechaImpresion ? styles.inputError : styles.input_radio}
                  />
                  <label htmlFor='hoy' className={styles.label}>De hoy</label>
                </div>
                <div className={styles.inputRadio}>
                  <input
                    type='radio'
                    id='periodo'
                    value='periodo'
                    {...register('tipoFechaImpresion', { required: true })}
                  />
                  <label htmlFor='periodo' className={styles.label}>De un período entre dos fechas</label>
                </div>

                <div>
                  {isActivoPeriodoFecha && (
                    <div className={styles.containerInputFechas}>
                      <div>
                        <label htmlFor="fechaDesde" className={styles.label}>Desde:</label>
                        <input
                          type="date"
                          name="fechaDesde"
                          id="fechaDesde"
                          className={styles.input}
                          {...register('fechaDesde')}
                        />
                      </div>
                      <div>
                        <label htmlFor="fechaHasta" className={styles.label}>Hasta:</label>
                        <input
                          type="date"
                          name="fechaHasta"
                          id="fechaHasta"
                          className={styles.input}
                          {...register('fechaHasta')}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              {hasErrors && (
                <Alert typeAlert={'error'}>
                  <p>Todos los campos son obligatorios</p>
                </Alert>
              )}
              <div className={styles.containerButtons}>
                <button
                  type="button"
                  placeholder='Limpiar'
                  onClick={handleClickLimpiar}
                  className='texto_con_icono'
                >
                  Limpiar <FontAwesomeIcon icon={faBrush} color='cyan' />
                </button>
                <button
                  type="submit"
                  placeholder='Imprimir'
                  value='Imprimir'
                  className='texto_con_icono'
                >
                  Imprimir <FontAwesomeIcon icon={faSave} color='cyan' />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ReporteActaEvaluaciones