import React, { useState } from 'react';
import { validarQRToken } from '../../qrService';
import styles from '../components/QRValidator.module.css';

const QRValidatorPage = () => {
  const [token, setToken] = useState('');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleTokenChange = (event) => {
    setToken(event.target.value);
  };

  const handleValidation = async () => {
    try {
      const res = await validarQRToken(token);
      setResponse(res);
      setError(null);
    } catch (err) {
      setError('Validation failed. Please try again.');
      setResponse(null);
    }
  };
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Validador Código QR</h2>
      <div className={styles.inputGroup}>
        <input
          type="text"
          value={token}
          onChange={handleTokenChange}
          placeholder="Ingresar token QR"
          className={styles.input}
        />
        <button onClick={handleValidation} className={styles.button}>Validar Token</button>
      </div>
      {response && (
        <div className={styles.resultContainer}>
          <h3 className={styles.successMessage}>QR Encontrado</h3>
          <table className={styles.table}>
            <thead>
              <tr>
              <th>ID</th>
                <th>Tipo</th>
                <th>ID QR</th>
                <th>Status</th>
                <th>No Oficio</th>
                <th>CRN</th>
                <th>Nombre Profesor</th>
                <th>Ciclo Escolar</th>
                <th>Jefe Departamento</th>
                <th>Calificación</th>
                <th>Ubicación</th>
                <th>Cargo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{response.id}</td>
                <td>{response.tipo}</td>
                <td>{response.idQr}</td>
                <td>{response.status}</td>
                <td>{response.noOficio}</td>
                <td>{response.crn}</td>
                <td>{response.nombreProfesor}</td>
                <td>{response.cicloEscolar}</td>
                <td>{response.jefeDepartamento}</td>
                <td>{response.calificacion}</td>
                <td>{response.ubicacion}</td>
                <td>{response.cargo}</td>
              </tr>
            </tbody>
          </table>
          </div>
      )}
      {error && (
        <div className={styles.errorContainer}>
          <h3 className={styles.errorTitle}>Error</h3>
          <p className={styles.errorMessage}>{error}</p>
        </div>
      )}
    </div>
  );
};

export default QRValidatorPage;

             