// Custom hook: useMunicipioChange.js
import { useState } from 'react';
import { actualizarMunicipioCartaDesempeno } from '../features/municipiosService';

const useMunicipioChange = (municipios, selectedCiclo, filteredMaterias, setFilteredMaterias) => {
  const [municipioError, setMunicipioError] = useState(null);

  const handleMunicipioChange = async (newMunicipio) => {
    if (newMunicipio) {
      aplicarMunicipioATodas(newMunicipio);
      try {
        await actualizarMunicipioCartaDesempeno({
          codigoEmpleado: "XXSAFS3",
          cicloEscolar: selectedCiclo,
          app: "Prueba",
          idMunicipio: municipios.find((municipio) => municipio.municipio === newMunicipio)?.id || ""
        });
        console.log("Municipio de la carta de desempeño actualizado correctamente.");
      } catch (error) {
        console.error("Error al actualizar el municipio de la carta de desempeño:", error);
        setMunicipioError(error);
      }
    }
  };

  const aplicarMunicipioATodas = (municipio) => {
    if (municipio) {
      const updatedMaterias = filteredMaterias.map((materia) => ({
        ...materia,
        ubicacion: municipio,
        statusMunicipio: "0"
      }));
      setFilteredMaterias(updatedMaterias);
    }
  };

  return { handleMunicipioChange, municipioError };
};

export default useMunicipioChange;
