import React, { useState } from 'react';
import Select from './Select';
import Search from './Search';
import FormModal from './FormModal';
import TableSearch from './TableSearch';
import { useSearchStore } from '../store/useSearchStore';
import { VscArrowBoth } from "react-icons/vsc";
import { useEvaluationStore } from '../store/useEvaluationStore';
import styles from './Aside.module.css';
import iconAmarillo from '../assets/images/note-edit-icon_amarillo.png';
import icon from '../assets/images/note-edit-icon.png';


const Aside = ({ setInfoAcademico }) => {

  const { selectedDataAcademico, findAndSetDataAcademico } = useEvaluationStore();
  const academicos = useSearchStore((state) => state.academicos);
  const [isSelected, setIsSelected] = useState(0)
  const [showModal, setShowModal] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const handleClickAcademico = (id) => {
    const dataAcademico = findAndSetDataAcademico(id, academicos);
    setIsSelected(dataAcademico.codigo);
  };

  const handleButtonClick = () => setShowTable((prev) => !prev);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <aside className={styles.aside}>
      <div className={styles.filters}>
        <div>
          <Select placeholder={'Seleccionar'} />
        </div>
        <div className={styles.filter_search}>
          <Search />
          <div></div>
        </div>

        <button className={styles.pBusquedaAvanzada} onClick={handleShowModal}>Búsqueda avanzada</button>
        <button className={styles.arrowBoth} onClick={handleButtonClick}><VscArrowBoth /></button>

        {showTable && (
          <>
            <div className={styles.backdrop} onClick={() => setShowTable(false)} />
            <TableSearch participantes={academicos} />
          </>
        )}
      </div>

      <div className={styles.containerList}>
        {academicos && academicos.length === 0 ? (
          <div>No hay participantes registrados que coincidan con los criterios de búsqueda.</div>
        ) :
          (academicos?.map(academico => (
            <div
              key={academico.codigo}
              className={isSelected === academico.codigo ? `${styles.isSelected} ${styles.item_list}` : `${styles.item_list}`}
              onClick={() => handleClickAcademico(academico.id)}
            >
              <p className={styles.itemParrafo}>
                <span>{academico.codigo}.</span> {academico.apellidoPaterno} {academico.apellidoMaterno} {academico.nombre} {academico.inconformidad ? <span style={{ color: 'crimson' }}>[INC]</span> : ''}
                {academico.comentario && (
                  <td>
                    {academico.revisado ? (
                      <img src={icon} alt="Icon" />
                    ) : (
                      <img src={iconAmarillo} alt="Icon" />
                    )}
                  </td>
                )}

              </p>
              <p className={styles.parrafoSpan}>
                <span
                  className={academico.inconformidad ?
                    (academico.respuestaInconformidad ?
                      styles.eAtendido
                      : styles.eVacio)
                    : (academico.nivelRomano ?
                      styles.eEvaluado
                      : styles.eVacio)
                  }
                >
                  {academico.nivelRomano || '-'}
                </span>
                <span className={styles.spanTipo}>[{academico.nombreParticipacion.toUpperCase()}]</span>
              </p>
            </div>
          )))}

      </div>

      <FormModal show={showModal} onHide={handleCloseModal} />
    </aside>
  );
}

export default Aside;
