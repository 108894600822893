import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useEvaluationStore = create(
  persist(
    (set, get) => ({
      selectedDataAcademico: null,
      // Función para encontrar el objeto por Id y establecerlo en selectedDataAcademico
      findAndSetDataAcademico: (id = 0, academicos = []) => {
        const academico = academicos.find(item => item.id === id);
        set({ selectedDataAcademico: academico });
        return academico;
      },
      // Función para verificar si selectedDataAcademico tiene valores en sus propiedades (retorna true o false depende el caso)
      hasSelectedDataAcademico: () => !!get().selectedDataAcademico,
      // Función para resetear selectedDataAcademico a null
      resetSelectedDataAcademico: () => set({ selectedDataAcademico: null }),
    }),
    { name: 'data-evaluation' }, // Opcional: nombre para almacenar el objeto en localStorage
  )
);
