import { Menu, useMenu } from '../../../reutilizable/Menu';
import { Footer, Header } from '../../layout/components';
import Dictamen from '../components/Dictamen';
import Inconformidad from '../components/Inconformidad';
import styles from './InconformidadPage.module.css';

export const InconformidadPage = () => {
  const menu = useMenu([
    {
      label: 'Inconformidad',
      element: <Inconformidad />
    },
    {
      label: 'Dictamen',
      element: <Dictamen />
    }
  ]);

  return (
    <>
      <Header />
      <Menu menu={menu} />
      <div className={styles.container}>
        {menu.element}
      </div>
      <Footer />
    </>
  );
};
