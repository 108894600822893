import { LoginLayout } from "../components/Layout";
import { Footer } from "../components";


export const LoginPage = () => {
  return (
    <>
      <div>
        <LoginLayout />
        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};
