import { useState, useEffect } from 'react';
import { obtenerMunicipios } from "../features/municipiosService";

const useMunicipios = () => {
  const [municipios, setMunicipios] = useState([]);

  useEffect(() => {
    const fetchMunicipios = async () => {
      try {
        const data = await obtenerMunicipios();
        setMunicipios(data);
      } catch (error) {
        console.error("Error al obtener municipios:", error);
      }
    };
    fetchMunicipios();
  }, []);

  return { municipios };
};

export default useMunicipios;