import axios from 'axios';
import { getAccessToken } from '../../authService';  // Asumimos que tienes el servicio de autenticación separado

// Obtener la URL base desde las variables de entorno
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_OBTENER_COMENTARIOS_URL = `${API_BASE_URL}/api/v1/evaluacion/obtener-comentarios?idSolicitud=1`;
const API_AGREGAR_COMENTARIO_URL = `${API_BASE_URL}/api/v1/evaluacion/agregar-comentario`;
const API_REVISAR_COMENTARIO_URL = `${API_BASE_URL}/api/v1/evaluacion/revisar`;

export const getComentarios = async () => {
  try {
    const token = await getAccessToken();
    const response = await axios.get(API_OBTENER_COMENTARIOS_URL, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error getting comentarios: ', error);
    throw error;
  }
};

export const postComentario = async (body) => {
  try {
    const token = await getAccessToken();
    const response = await axios.post(API_AGREGAR_COMENTARIO_URL, body, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
    return response;
  } catch (error) {
    console.error('Error adding comentario: ', error);
    throw error;
  }
};

export const putComentario = async (body) => {
  try {
    const token = await getAccessToken();
    const response = await axios.put(API_REVISAR_COMENTARIO_URL, body, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
    return response.data;
  } catch (error) {
    console.error('Error updating comentario: ', error);
    throw error;
  }
};