import React from 'react';
import styles from './RowHeadTable.module.css';

/**
 * Componente que representa la fila de cabeceras de una tabla.
 * 
 * @param {Object} props - Las propiedades del componente.
 * @param {Array<{ id: number, labelCabecera: string }>} props.cabecerasTable - Un arreglo de objetos que representan las cabeceras de la tabla.
 * @returns {JSX.Element} El elemento JSX que representa la fila de cabeceras.
 */

const RowHeadTable = ({ cabecerasTable }) => {
  return (
    <tr className={styles.rowHead}>
      {cabecerasTable.map((cabecera, index) => {
         if (index === 0) {
          return (
            <th key={cabecera.id} className={styles.rowHead}>
              <input type="checkbox" />
            </th>
          );
        }
        return (
          <th key={cabecera.id} className={styles.rowHead}>
            {cabecera.labelCabecera}
          </th>
        );
      })}
    </tr>
  )
}

export default RowHeadTable
