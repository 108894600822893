import { Header } from "./header";
import { LoginComponent } from "./login";

export const LoginLayout = () => {

  return (
    <>
      <Header />
      <LoginComponent />
    </>
  );
};
